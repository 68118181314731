const globalMessagesCache: Record<string, Record<string, unknown>> = {};

export default defineNuxtRouteMiddleware(async to => {
    const nuxtApp = useNuxtApp();
    const i18n = nuxtApp.$i18n;

    if (!to.name) return;

    const [pageKey, locale] = to.name.toString().split('___');
    const pagePath = pageKey.replace(/-/g, '/');

    console.log('🌐 Chargement des traductions pour', `~/i18n/locales/${pagePath}/${locale}.ts`);

    try {
        // Charger les traductions spécifiques à la page
        const pageMessages = await import(`~/i18n/locales/${pagePath}/${locale}.ts`);

        // Si c'est la première fois, on sauvegarde les traductions globales
        if (!globalMessagesCache[locale]) {
            globalMessagesCache[locale] = { ...i18n.getLocaleMessage(locale) };
        }

        i18n.setLocaleMessage(locale, {
            ...globalMessagesCache[locale], // Conserver les traductions globales
            ...pageMessages.default // Charger uniquement la page actuelle
        });
    } catch {
        console.warn(`⚠️ Pas de fichier de traduction pour ${pagePath} (${locale})`);
    }
});
