// Définition des permissions par masques binaires

enum AdminTasksPermissions {
    NO_ACCESS = 0b0, // Aucun accès
    FULL_ACCESS = 0b1 // Accès total
}

type AdminTasksPermissionsDescription = {
    [key in keyof typeof AdminTasksPermissions]: string;
};

enum ImagesPermissions {
    NO_ACCESS = 0b0, // Aucun accès
    CREATE = 0b1, // peut creer une nouvelle image
    PUBLISH = 0b10, // peut publier une nouvelle image
    SEE_UNPUBLISHED_SELF = 0b100, // peut voir ses images non publiées
    SEE_UNPUBLISHED_GROUPE = 0b1000, // peut voir les images non publiées de son groupe d'utilisateurs
    SEE_UNPUBLISHED = 0b10000, // peut voir toutes les images non publiées
    EDIT_SELF = 0b100000, // peut editer ses images
    EDIT_GROUPE = 0b1000000, // peut editer les images de son groupe d'utilisateurs
    EDIT = 0b10000000, // peut editer toutes les images
    DELETE_SELF = 0b100000000, // peut supprimer ses images
    DELETE_GROUPE = 0b1000000000, // "peut supprimer les images de son groupe d'utilisateur",
    DELETE = 0b10000000000, // peut supprimer des images
    FULL_ACCESS = 0b11111111111 // Accès total
}

type ImagesPermissionsDescription = {
    [key in keyof typeof ImagesPermissions]: string;
};

enum TagsPermissions {
    NO_ACCESS = 0b0, // Aucun accès
    CREATE = 0b1, // peut creer des tags
    DELETE = 0b10, // peut supprimer des tags
    EDIT = 0b100, // peut editer des tags
    FULL_ACCESS = 0b111 // Accès total
}

type TagsPermissionsDescription = {
    [key in keyof typeof TagsPermissions]: string;
};

enum PermissionPermissions {
    NO_ACCESS = 0b0, // Aucun accès
    READ_SELF = 0b1, // peut voir les noms profiles de permissions
    READ_ALL = 0b10, // peut voir les profiles de permissions
    CREATE = 0b100, // peut creer un nouveau profile de permissions
    EDIT = 0b1000, // peut editer un profile de permissions
    DELETE = 0b10000, // peut supprimer un profile de permissions
    FULL_ACCESS = 0b11111 // Accès total
}

type PermissionPermissionsDescription = {
    [key in keyof typeof PermissionPermissions]: string;
};

enum UserPermissions {
    NO_ACCESS = 0b0, // Aucun accès
    READ_SELF = 0b1, // Lecture de ses propres données
    READ_ALL = 0b100, // Lecture des données des autres utilisateurs
    WRITE_SELF = 0b10, // Modification de ses propres données
    WRITE_ALL = 0b1000, // Modification des données des autres utilisateurs
    CREATE = 0b10000,
    DELETE_SELF = 0b100000,
    DELETE_ALL = 0b1000000,
    SET_PERMISSIONS = 0b10000000,
    SET_DEFAULT_PERMISSIONS_SELF = 0b100000000,
    SET_DEFAULT_PERMISSIONS_ALL = 0b1000000000,

    FULL_ACCESS = 0b1111111111 // Accès total
}

type UserPermissionsDescription = {
    [key in keyof typeof UserPermissions]: string;
};

enum AccountPermissions {
    NO_ACCESS = 0b00, // Aucun accès
    READ_SELF = 0b1, // Lecture de ses propres données
    READ_ALL = 0b010, // Lecture uniquement
    WRITE_SELF = 0b100, // Modification de ses propres données
    WRITE_ALL = 0b1000, // Modification de toutes les données
    CREATE = 0b10000,
    DELETE_SELF = 0b100000,
    DELETE_ALL = 0b1000000,
    FULL_ACCESS = 0b1111111 // Lecture et écriture
}

type AccountPermissionsDescription = {
    [key in keyof typeof AccountPermissions]: string;
};

enum PlanningPermissions {
    NO_ACCESS = 0b00, // Aucun accès
    READ = 0b01, // Lecture uniquement
    WRITE = 0b10,
    FULL_ACCESS = 0b11 // Lecture et écriture
}

type PlanningPermissionsDescription = {
    [key in keyof typeof PlanningPermissions]: string;
};

type PermissionsDescriptionsMap<T> = T extends typeof UserPermissions
    ? UserPermissionsDescription
    : T extends typeof AccountPermissions
      ? AccountPermissionsDescription
      : T extends typeof PlanningPermissions
        ? PlanningPermissionsDescription
        : T extends typeof PermissionPermissions
          ? PermissionPermissionsDescription
          : T extends typeof ImagesPermissions
            ? ImagesPermissionsDescription
            : T extends typeof TagsPermissions
              ? TagsPermissionsDescription
              : T extends typeof AdminTasksPermissions
                ? AdminTasksPermissionsDescription
                : never;

// Gestionnaire de permissions
export const AppPermissions = {
    AdminTask: AdminTasksPermissions,
    User: UserPermissions,
    Account: AccountPermissions,
    Planning: PlanningPermissions,
    Permission: PermissionPermissions,
    Image: ImagesPermissions,
    Tag: TagsPermissions
} as const;

export type AppPermissionKeys = keyof typeof AppPermissions;

type AppPermissionsDescription = {
    [K in AppPermissionKeys]: PermissionsDescriptionsMap<(typeof AppPermissions)[K]>;
};

export const AppPermissionsDescriptionPermission: AppPermissionsDescription = {
    AdminTask: {
        NO_ACCESS: "pas d'acces",
        FULL_ACCESS: 'acces total'
    },
    User: {
        NO_ACCESS: 'Aucun accès',
        READ_SELF: 'Lecture de ses propres données',
        READ_ALL: 'Lecture des données des autres utilisateurs',
        WRITE_SELF: 'Modification de ses propres données',
        WRITE_ALL: 'Modification des données des autres utilisateurs',
        CREATE: 'peut creer un nouvel utilisateur',
        DELETE_SELF: 'peut supprimer son compte utilisateur',
        DELETE_ALL: 'peut supprimer tout les comptes utilisateur',
        SET_PERMISSIONS: 'peut attribuer des permissions aux utilisateurs',
        SET_DEFAULT_PERMISSIONS_SELF: 'peut attribuer la permission par défaut à son compte',
        SET_DEFAULT_PERMISSIONS_ALL: 'peut attribuer la permission par défaut à tout les comptes',
        FULL_ACCESS: 'Accès complet'
    },
    Account: {
        NO_ACCESS: 'Aucun accès',
        READ_SELF: 'Lecture de son profile utilisateur',
        READ_ALL: 'Lecture de tous les profiles utilisateur',
        WRITE_SELF: 'Modification de son profile utilisateur',
        WRITE_ALL: 'Modification de tous les profiles utilisateur',
        CREATE: 'peut creer un nouveau profile utilisateur',
        DELETE_SELF: 'peut supprimer son profile utilisateur',
        DELETE_ALL: 'peut supprimer tout les profiles utilisateur',
        FULL_ACCESS: 'Accès complet'
    },
    Planning: {
        NO_ACCESS: 'Aucun accès',
        READ: 'Création de plannings',
        WRITE: 'Modification des plannings',
        FULL_ACCESS: 'Accès complet aux plannings'
    },
    Permission: {
        NO_ACCESS: 'Aucun accès',
        READ_SELF: 'peut voir ses profiles de permissions',
        READ_ALL: 'peut voir les profiles de permissions',
        CREATE: 'Peut creer un nouveau profile de permissions',
        EDIT: 'Peut editer un profile de permissions',
        DELETE: 'Peut supprimer un profile de permissions',
        FULL_ACCESS: 'Accès total'
    },
    Image: {
        NO_ACCESS: 'Aucun accès',
        CREATE: 'peut creer une nouvelle image',
        PUBLISH: 'peut publier une nouvelle image',
        SEE_UNPUBLISHED_SELF: 'peut voir ses images non publiées',
        SEE_UNPUBLISHED_GROUPE: "peut voir les images non publiées de son groupe d'utilisateurs",
        SEE_UNPUBLISHED: 'peut voir toutes les images non publiées',
        EDIT_SELF: 'peut editer ses images',
        EDIT_GROUPE: "peut editer les images de son groupe d'utilisateurs",
        EDIT: 'peut editer toutes les images',
        DELETE_SELF: 'peut supprimer ses images',
        DELETE_GROUPE: "peut supprimer les images de son groupe d'utilisateur",
        DELETE: 'peut supprimer toutes les images',
        FULL_ACCESS: 'Accès total'
    },
    Tag: {
        NO_ACCESS: 'Aucun accès',
        CREATE: 'peut creer des tags',
        DELETE: 'peut supprimer des tags',
        EDIT: 'peut editer des tags',
        FULL_ACCESS: 'Accès total'
    }
};

class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ValidationError';
    }
}

class PermissionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PermissionError';
    }
}

export const SEPARATOR = '-';

/**
 * ajoute la permission du token token passé en parametre
 *
 * @param authToken - Le token d'autorisation (sous forme de chaîne).
 * @param type - La catégorie de permission (User, Account, Planning, etc.)
 * @param permission - La permission spécifique à ajouter.
 * @returns Un token d'autorisation mis à jour sous forme de chaîne.
 * @throws ValidationError - Si la chaîne d'entrée est invalide.
 * @throws PermissionError - Si la permission ou le type est invalide.
 */
export const addPermission = <T extends keyof typeof AppPermissions>(
    authToken: string,
    type: T,
    permission: keyof (typeof AppPermissions)[T]
): string => {
    if (!AppPermissions[type] || !(permission in AppPermissions[type])) {
        throw new PermissionError(`Permission "${String(permission)}" invalide pour le type "${type}".`);
    }

    const position = Object.keys(AppPermissions).indexOf(type); // Obtenir la position dans l'objet
    const permissionValue = AppPermissions[type][permission]; // Convertir la chaîne en sa valeur numérique

    if (permissionValue === undefined) {
        throw new PermissionError(`Permission "${String(permission)}" invalide pour le type "${type}".`);
    }

    authToken = authToken.trim();

    if (!isValide(authToken, SEPARATOR)) {
        // ok pour "0" "0-2" "3-4-5" ou "", rejeté pour "abc", "0-a-1" "0--2" "-" "0-"
        throw new ValidationError("La chaîne d'entrée n'est pas valide");
    }

    // Remplir ou modifier le token
    if (authToken.split(SEPARATOR).length < position + 1) authToken += SEPARATOR;

    const padded = authToken
        .padEnd(position + 1, `0${SEPARATOR}`)
        .split(SEPARATOR)
        .map(v => Number(v) || 0);
    if (permissionValue === 0) {
        padded[position] = 0;
    } else {
        padded[position] |= permissionValue as number;
    }
    console.log('-----------------------------------', type, permission, position, padded, '----------------------------------------');
    return padded.join('-');
};

/**
 * supprime la permission du token token passé en parametre.
 *
 * @param authToken - Le token d'autorisation (sous forme de chaîne).
 * @param type - La catégorie de permission (User, Account, Planning, etc.)
 * @param permission - La permission spécifique à ajouter.
 * @returns Un token d'autorisation mis à jour sous forme de chaîne.
 * @throws ValidationError - Si la chaîne d'entrée est invalide.
 * @throws PermissionError - Si la permission ou le type est invalide.
 */
export const removePermission = <T extends keyof typeof AppPermissions>(
    authToken: string,
    type: T,
    permission: keyof (typeof AppPermissions)[T]
): string => {
    if (!AppPermissions[type] || !(permission in AppPermissions[type])) {
        throw new PermissionError(`Permission "${String(permission)}" invalide pour le type "${type}".`);
    }

    const position = Object.keys(AppPermissions).indexOf(type); // Obtenir la position dans l'objet
    const permissionValue = AppPermissions[type][permission]; // Convertir la chaîne en sa valeur numérique

    if (permissionValue === undefined) {
        throw new PermissionError(`Permission "${String(permission)}" invalide pour le type "${type}".`);
    }

    authToken = authToken.trim();

    if (!isValide(authToken, SEPARATOR)) {
        // ok pour "0" "0-2" "3-4-5" ou "", rejeté pour "abc", "0-a-1" "0--2" "-" "0-"
        throw new ValidationError("La chaîne d'entrée n'est pas valide");
    }

    // Remplir ou modifier le token
    if (authToken.split(SEPARATOR).length < position + 1) authToken += SEPARATOR;

    const padded = authToken
        .padEnd(position + 1, `0${SEPARATOR}`)
        .split(SEPARATOR)
        .map(v => Number(v) || 0);

    padded[position] = padded[position] & ~(permissionValue as number);
    console.log('-----------------------------------', type, permission, position, padded, '----------------------------------------');
    return padded.join('-');
};

/**
 * Permet de tester si les permissions sont contenues dans le authToken passé en parametre
 *
 * @param authToken - Le token d'autorisation (sous forme de chaîne).
 * @param type La catégorie de permission (User, Account, Planning, etc.)
 * @param permissions - Les permission spécifique à tester.
 * @returns true si les ermissions sont satisfaites (attention, si authToken comporte une erreur, renverra toujours false)
 */
export const hasPermissions = <T extends keyof typeof AppPermissions>(
    authToken: string,
    type: T,
    ...permissions: Array<keyof (typeof AppPermissions)[T]>
): boolean => hasOneOfPermission(false, authToken, type, ...permissions);

/**
 * Permet de tester si au moins une des permissions est contenues dans le authToken passé en parametre
 *
 * @param authToken - Le token d'autorisation (sous forme de chaîne).
 * @param type La catégorie de permission (User, Account, Planning, etc.)
 * @param permissions - Les permission spécifique à tester.
 * @returns true si au moins une des permissions est satisfaites (attention, si authToken comporte une erreur, renverra toujours false)
 */
export const hasOneOfPermissions = <T extends keyof typeof AppPermissions>(
    authToken: string,
    type: T,
    ...permissions: Array<keyof (typeof AppPermissions)[T]>
): boolean => hasOneOfPermission(true, authToken, type, ...permissions);

const hasOneOfPermission = <T extends keyof typeof AppPermissions>(
    oneOf: boolean,
    authToken: string,
    type: T,
    ...permissions: Array<keyof (typeof AppPermissions)[T]>
): boolean => {
    if (!AppPermissions[type] || !permissions.every(v => v in AppPermissions[type])) {
        throw new PermissionError(`au moins une permission invalide pour le type "${type}, verifié vos parametres".`);
    }

    if (!isValide(authToken, SEPARATOR)) return false;

    const position = Object.keys(AppPermissions).indexOf(type); // Obtenir la position dans l'objet
    const authNumber = Number(authToken.split(SEPARATOR)[position]) || 0;

    const validePermissions = getMatchingPermissions(AppPermissions[type], authNumber);

    //console.log(authToken, authNumber, validePermissions, permissions)
    return oneOf ? permissions.some(v => validePermissions.some(val => val === v)) : permissions.every(v => validePermissions.some(val => val === v));
};

/**
 * renvoie les permissions accordées contenues dans le AuthToken
 *
 * @param authToken - Le token d'autorisation (sous forme de chaîne).
 * @param type La catégorie de permission (User, Account, Planning, etc.)
 * @returns un array contenant les permissions accordées (string)
 */
export const getPermissions = <T extends keyof typeof AppPermissions>(authToken: string, type: T): string[] => {
    if (!AppPermissions[type] || !isValide(authToken, SEPARATOR)) return [];

    const position = Object.keys(AppPermissions).indexOf(type); // Obtenir la position dans l'objet
    const authNumber = Number(authToken.split(SEPARATOR)[position]) || 0;

    const validePermissions = getMatchingPermissions(AppPermissions[type], authNumber);

    return validePermissions;
};

/**
 * permet de verifier si le authToken est valide: bien formaté et valeurs qui correspondent strictement aux permissions
 *  ou combinaissons de permissions
 *
 * @param authToken un string contenant les valeurs de permissions
 * @param SEPARATOR un caractere qui separe les valeurs
 * @returns boolean
 */
export function isValide(authToken: string, SEPARATOR: string): boolean {
    // ok pour "0" "0-2" "3-4-5" ou "", rejeté pour "abc", "0-a-1" "0--2" "-" "0-"
    if (!/^(\d+(-\d+)*)?$/.test(authToken)) return false;

    return authToken.split(SEPARATOR).every((v, i) => {
        try {
            type PermissionsKeys = keyof typeof AppPermissions; // "User" | "Account" | "Planning"
            // Récupérer la clé à la position i
            const keys = Object.keys(AppPermissions) as PermissionsKeys[]; // Type sûr des clés
            // Accéder à la valeur à la position i
            const keyAtPositionI = keys[i];
            const valueAtPositionI = AppPermissions[keyAtPositionI];
            //console.log(valueAtPositionI, v, Number(v))
            getMatchingPermissions(valueAtPositionI, Number(v));
            //console.log(t)
            return true;
        } catch {
            return false;
        }
    });
}

/**
 * Fonction pour vérifier si une valeur numérique correspond à des permissions spécifiques
 * @param permissions l'enum qui defini' les permissions
 * @param value un chiffre qui represente les permissions accordées
 * @returns les permissons accordées en tableau de string
 */
function getMatchingPermissions(permissions: (typeof AppPermissions)[keyof typeof AppPermissions], value: number): string[] {
    // Vérification de la validité de la valeur d'entrée
    if (typeof value !== 'number' || value < 0) {
        throw new Error("La valeur d'entrée doit être un nombre positif.");
    }

    const filteredPermissions = Object.entries(permissions)
        .filter(([key]) => isNaN(Number(key))) // Filtrer les clés qui sont des nombres (index)
        .reduce<Record<string, number>>((acc, [key, value]) => {
            acc[key] = value; // Ajouter la clé et la valeur dans le nouvel objet
            return acc;
        }, {});

    // Vérification que la valeur n'est pas supérieure à la valeur maximale des permissions possibles
    const maxPermissionValue = Math.max(...Object.values(filteredPermissions));
    if (value > maxPermissionValue) {
        throw new Error(`La valeur d'entrée ${value} dépasse la valeur maximale des permissions (${maxPermissionValue}).`);
    }

    // Vérification si la valeur correspond à une ou plusieurs permissions
    const matchingPermissions = Object.keys(filteredPermissions)
        .filter(key => filteredPermissions[key] !== 0 && (value & filteredPermissions[key]) === filteredPermissions[key]) // Vérifie si chaque permission correspond à la valeur
        .map(key => key); // Retourne les permissions qui correspondent

    // Si la valeur est 0 et que la permission correspond à 0, inclure dynamiquement
    if (value === 0) {
        // Identifier la permission ayant la valeur 0 (sans codage en dur)
        const zeroPermissionKey = Object.keys(filteredPermissions).find(key => filteredPermissions[key] === 0);
        if (zeroPermissionKey) matchingPermissions.push(zeroPermissionKey); // Ajoute la permission '0' de manière générique
    }

    // Si aucune permission n'est trouvée
    if (matchingPermissions.length === 0 || matchingPermissions.reduce((acc, curr) => acc | filteredPermissions[curr], 0) !== value) {
        throw new Error(`La valeur d'entrée ${value} ne correspond à aucune combinaison valide de permissions.`);
    }

    return matchingPermissions;
}

// let aar = removePermission('', 'Account', 'A_WRITE')
// aar = addPermission(aar, 'Planning', 'P_WRITE')
// aar = addPermission(aar, 'User', 'READ_OTHERS')
// aar = addPermission(aar, 'User', 'READ_SELF')
// aar = addPermission(aar, 'User', 'WRITE_OTHERS')
// aar = addPermission(aar, 'User', 'WRITE_SELF')
// aar = addPermission(aar, 'Planning', 'P_READ')
// console.log('getPermissions', getPermissions(aar, 'User'))
// aar = removePermission(aar, 'User', 'WRITE_OTHERS')
// aar = removePermission(aar, 'User', 'READ_SELF')
// aar = addPermission(aar, 'Account', 'FULL_ACCESS')
// aar = removePermission(aar, 'Planning', 'FULL_ACCESS')

// console.log('*************')
// performance.mark('start');
// console.log('hasPermissions', hasPermissions(aar, 'Planning', 'P_NO_ACCESS'))
// console.log('getPermissions', getPermissions(aar, 'User'))
// performance.mark('end');
// performance.measure('start to end')
// console.log(performance.getEntries())

// console.log(aar, isValide(aar, '-'))
