import payload_plugin_qLmFnukI99 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_NxDDSatxox from "/opt/buildhome/repo/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_client_4F1kZh3YAB from "/opt/buildhome/repo/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import slideover_ZLoaDmlvnw from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideover.js";
import modal_X5eKYYwod7 from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modal.js";
import colors_ETtiVmZNmO from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_WLsn00x1qh from "/opt/buildhome/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import dbi18n_CVkdEt7jqj from "/opt/buildhome/repo/plugins/dbi18n.ts";
import pluginScrollDirection_Kruj9Kl6QC from "/opt/buildhome/repo/plugins/pluginScrollDirection.ts";
import pluginSavePagePosition_client_27fEOP7arq from "/opt/buildhome/repo/plugins/pluginSavePagePosition.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  session_client_NxDDSatxox,
  plugin_client_4F1kZh3YAB,
  slideover_ZLoaDmlvnw,
  modal_X5eKYYwod7,
  colors_ETtiVmZNmO,
  plugin_WLsn00x1qh,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  dbi18n_CVkdEt7jqj,
  pluginScrollDirection_Kruj9Kl6QC,
  pluginSavePagePosition_client_27fEOP7arq
]