export default defineAppConfig({
    ui: {
        colors: {
            primary: 'gege',
            secondary: 'sky',
            lolo: 'lolo',
            neutral: 'neutral'
        },

        modal: {
            slots: { overlay: 'backdrop-blur-lg backdrop-saturate-50 bg-ui-bg/0' },
            variants: {
                fullscreen: {
                    false: {
                        //content: 'max-w-lg w-[95vw] h-auto rounded-[calc(var(--ui-radius)*2)] shadow-lg ring'
                        content:
                            'sm:max-w-lg w-[100vw]  max-sm:rounded-t-[calc(var(--ui-radius)*2)] shadow-lg ring max-sm:translate-y-0 max-sm:top-auto max-sm:bottom-0 h-auto'
                    }
                }
            }
        }
    }
});
