<template>
    <!-- <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">

  <Head>

    <template v-for="link in head.link" :key="link.id">
      <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
    </template>
<template v-for="meta in head.meta" :key="meta.id">
      <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
    </template>
</Head> -->

    <!-- <Body> -->
    <UApp :toaster="{ position: $viewport.isLessThan('md') ? 'bottom-center' : 'top-center' }" :locale="locales[locale as 'fr' | 'en']" class="">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </UApp>
    <!-- </Body> -->

    <!-- </Html> -->
</template>
<script setup lang="ts">
import { fr, en } from '@nuxt/ui/locale';
const locales = { fr, en };
const { t, locale } = useI18n();

useSeoMeta({
    title: t('titre'),
    applicationName: 'Air&Montagne',
    description: t('description')
});

const localeHead = useLocaleHead({
    dir: true,
    key: 'hid',
    seo: true
});

useHead({
    htmlAttrs: {
        lang: localeHead.value.htmlAttrs!.lang,
        dir: localeHead.value.htmlAttrs!.dir,
        class: 'font-nunito dark:scheme-dark'
    },

    link: [...(localeHead.value.link || [])],
    meta: [...(localeHead.value.meta || [])]
});
</script>
<style></style>
